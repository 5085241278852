.socials {
    color: #d1d2d8;
    background-color: rgb(24, 24, 24);
}

.socials .container > header > h2 {
    color: #eebe46;
    margin: 0;
}

.socials .container hr.rounded {
    border-top: 4px solid #eebe46;
    border-radius: 3px;
    width: 50px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    opacity: .75;
}

.socials .container svg {
    margin: 16px;
}

.socials .container a {
    text-decoration: none;
    color: #d1d2d8;
}

.socials .container a:hover {
    color: #909298;
}

@media (max-width: 768px) {
    .socials .container a svg {
        width: 40px;
        height: 40px;
    }
}