
.my-navbar {
    color: #d1d2d8;
    background-color: transparent;
    position: fixed;
    width: 100%;
    top: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    z-index: 1000;
}

.my-navbar .hamburger {
    margin-left: auto;
    z-index: 2000;
    color: #eebe46;}

.my-navbar .nav-list-items {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    opacity: .93;
    background-color: #626262
}

.my-navbar .nav-list-container {
    width: 100%;
}

.nav-list-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1500;
}