.divider-1 {
    background: linear-gradient(#000000, #282828);
    padding: 0;
    height: 100px;
    width: 100%;
}

.divider-2 {
    background: linear-gradient(#282828, #181818);
    padding: 0;
    height: 100px;
    width: 100%;
}

.divider-3 {
    background: linear-gradient(#181818, #000000);
    padding: 0;
    height: 100px;
    width: 100%;
}