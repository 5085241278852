@media (max-width: 991px) {
    .oldschool div img{
        margin-top: 28px;
        margin-bottom: 60px;
    }

    .oldschool .container hr.rounded {
        display: none;
    }
}

.oldschool div img {
    max-width: 250px;
    object-fit: contain;
}

.oldschool {
    color: #d1d2d8;
    background-color: black;
}

.oldschool .container > header > h2 {
    color: #eebe46;
    margin: 0;
}

.oldschool .container h3 {
    color: #eebe46;
    margin: 0 auto;
    padding-bottom: 24px;
}


.oldschool .container hr.rounded {
    border-top: 4px solid #eebe46;
    border-radius: 3px;
    width: 50px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    opacity: .75;
}

.oldschool a {
    color: #eebe46;
    text-decoration: none;
}

.oldschool a:hover {
    color: #8d712c;
}
