.wide-image .container {
    max-width: 1320px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
}

.wide-image img {
    width: 100%;
}

.wide-image {
    padding: 0;
}

.divider-4 {
    background: linear-gradient(#000000, #282828);
}

.divider-5 {
    background: linear-gradient(#282828, #181818);
}

.divider-6 {
    background: linear-gradient(#181818, #000000);
}