.references .row>div {
    padding: 2px;
}

.references .row>div:first-child {
    text-align: end;
}

.references {
    color: #d1d2d8;
    background-color: rgb(40,40,40);
}

.references .container > header > h2 {
    color: #eebe46;
    margin: 0;
}

.references .container hr.rounded {
    border-top: 4px solid #eebe46;
    border-radius: 3px;
    width: 50px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    opacity: .75;
}

.references .club-list {
    font-weight: 700;
}

.references .club-list>div:nth-child(2) {
    color: #eebe46
}
