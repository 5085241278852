.about .container {
    max-width: 800px;
    margin: 0 auto;
}

.about {
    color: #d1d2d8;
    background-color: rgb(40,40,40);
}

.about .container > header > h2 {
    color: #eebe46;
    margin: 0;
}

.about .container hr.rounded {
    border-top: 4px solid #eebe46;
    border-radius: 3px;
    width: 50px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    opacity: .75;
}