.dates .date-row {
    font-weight: 700;
    margin-bottom: 0;
}

.dates .date-row .date-row-date {
    color: #eebe46;
    font-weight: 800;
}

.dates .container {
    max-width: 800px;
    margin: 0 auto;
}

.dates {
    color: #d1d2d8;
    background-color: rgb(24, 24, 24);
}

.dates .container > header > h2 {
    color: #eebe46;
    margin: 0;
}

.dates .container hr.rounded {
    border-top: 4px solid #eebe46;
    border-radius: 3px;
    width: 50px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    opacity: .75;
}