/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-header {
    text-align: center;
    padding-bottom: 40px;
}

.section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #4154f1;
    text-transform: uppercase;
}

.section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
}

@media (max-width: 768px) {
    .section-header p {
        font-size: 28px;
        line-height: 32px;
    }
}

.hero {
    width: 100%;
    /*background: url(./mib_header.jpg) top center no-repeat;
    background-size: cover;*/
    background: black;
}

.hero .hero-img {
    width: 100%
}

.hero .hero-logo {
    width: 100%;
    /*filter: invert(0.7)*/
    opacity: .7;
}

.container {
    height: auto
}

.hero .filler {
    width: 100%;
    height: 100px;
        background: linear-gradient(#181818, #000000);
}

@media (min-width: 1024px) {
    .hero {
        background-attachment: fixed;
    }
}

@media (max-width: 991px) {
    .hero {
        height: auto;
        padding: 0 0 60px 0;
        text-align: center;
    }

    .hero .hero-img {
        text-align: center;
    }

    .hero .hero-logo {
        width: 80%;
        height: auto;
        margin: auto;
        margin-top: 24px;

    }
}

@media (max-width: 768px) {
    .hero {
        text-align: center;
    }

    .hero h1 {
        font-size: 32px;
    }

    .hero h2 {
        font-size: 24px;
    }
}

