.footer .container {
    max-width: 800px;
    margin: 0 auto;
}

.footer {
    color: #d1d2d8;
    background-color: black;
    font-size: 14px;
    font-weight: 400;
}

.footer a {
    color: #d1d2d8;
    text-decoration: none;
}

.footer a:hover {
    color: #909298;
}

.footer .container h4 {
    color: #eebe46;
}

.footer .footer-links {
    margin-bottom: 1rem;
}

.footer .container .row p a {
    color: #eebe46;
}

.footer .container .row p a:hover {
    color: #8d712c;
}